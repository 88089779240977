export const GAME_TITLE = 'Lison Wordle'

export const WIN_MESSAGES = [
  'Bravo !',
  'Génial !',
  'Alex te félicite !',
  'And the chip, and the chop, and the chip aux ketchups',
]
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Lettre(s) manquante(s)'
export const WORD_NOT_FOUND_MESSAGE = 'Mot non trouvé :('
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Le mot était ${solution}`
export const ENTER_TEXT = 'Entrer'
export const DELETE_TEXT = 'Effacer'
export const STATISTICS_TITLE = 'Statistiques'
export const GUESS_DISTRIBUTION_TEXT = 'Distribution des suppositions'
export const TOTAL_TRIES_TEXT = 'Essais totaux'
export const SUCCESS_RATE_TEXT = 'Taux de succès'
export const CURRENT_STREAK_TEXT = 'Séquence en cours'
export const BEST_STREAK_TEXT = 'Meilleure séquence'
